import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar,
  faCheck,
  faChevronDoubleRight,
  faExclamationTriangle,
  faNotes,
  faPlus,
  faTimes,
  faTire,
  faTools,
  faTruck,
  faUser
} from '@fortawesome/pro-regular-svg-icons';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BookingService } from './booking.service';
import { IFleetVehicle } from '../../interfaces/ifleet-vehicle';
import { MatStepper } from '@angular/material/stepper';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { faCar, faTireFlat, faTrailer } from '@fortawesome/pro-solid-svg-icons';
import { VehicleConfirmationDialogComponent } from './vehicle-confirmation-dialog/vehicle-confirmation-dialog.component';
import { CookieService } from 'ngx-cookie-service';
import { DpVehicleViewModel } from '../../shared/tams-api';
import { BehaviorSubject } from 'rxjs';
import { selectedDateTimeSlot } from './vehicle-availability-selection/time-slot-dialog/time-slot-dialog.component';

export class formESM implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
  activeVehicle: IFleetVehicle | null = null;
  vehicleConfirmed: boolean = false;
  duplicateResults: any[] = [];
  isTruck: boolean = false;

  protected readonly faCheck: IconDefinition = faCheck;
  protected readonly faNotes: IconDefinition = faNotes;
  protected readonly faTimes: IconDefinition = faTimes;
  protected readonly faTire: IconDefinition = faTire;
  protected readonly faUser: IconDefinition = faUser;
  protected readonly faPlus: IconDefinition = faPlus;
  protected readonly faTrailer = faTrailer;
  protected readonly faTruck = faTruck;
  protected readonly faCalendar = faCalendar;
  protected readonly faTireFlat = faTireFlat;
  protected readonly faExclamationTriangle: IconDefinition = faExclamationTriangle;
  protected readonly faCar = faCar;
  protected readonly faTools = faTools;
  protected readonly faChevronDoubleRight = faChevronDoubleRight;

  formSubmitted: boolean = false;
  jobDetails: FormGroup;
  registrationForm: FormGroup;
  gettingVehicle: boolean = false;
  matcher = new formESM();
  showUserIsDriverError: boolean[] = [];
  showJobItemsError: boolean[] = [];
  dtmContactNo: string = '01253 229102';
  submissionError: string | null = null;
  contactTitles: Array<{ name: string; value: string }> = [
    { name: 'Mr', value: 'Mr' },
    { name: 'Mrs', value: 'Mrs' },
    { name: 'Miss', value: 'Miss' },
    { name: 'Mx', value: 'Mx' },
    { name: 'Sir', value: 'Sir' },
    { name: 'Dr', value: 'Dr' },
    { name: 'Prof', value: 'Prof' }
  ];

  isSubmitting: boolean = false;
  attemptedNextStep = false;
  public detailsAppended: boolean = false;
  public bookTrailer: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

  constructor(
    public fb: FormBuilder,
    private bookingService: BookingService,
    private router: Router,
    private dialog: MatDialog,
    private cookieService: CookieService
  ) {
    this.jobDetails = this.fb.group({
      // User details that stay constant
      userContactNumber: new FormControl(this.cookieService.get('userContactNumber') || null, [
        Validators.required,
        Validators.pattern(/^\d{11}$/),
        Validators.maxLength(15)
      ]),
      userEmail: new FormControl(this.cookieService.get('userEmail') || '', [
        Validators.email,
        Validators.maxLength(100),
        Validators.required
      ]),
      userFirstName: new FormControl(this.cookieService.get('userFirstName') || '', [Validators.required, Validators.maxLength(50)]),
      userLastName: new FormControl(this.cookieService.get('userLastName') || '', [Validators.required, Validators.maxLength(50)]),
      userTitle: new FormControl(this.cookieService.get('userTitle') || '', [Validators.maxLength(10)]),
      // FormArray for vehicles
      vehicles: this.fb.array([])
    });

    this.registrationForm = this.fb.group({
      registration: new FormControl<string>('', [Validators.required, Validators.maxLength(15)]),
      trailerNumber: new FormControl<string>('', [])
    });
  }

  ngOnInit() {
    this.addVehicle();

    this.bookTrailer.subscribe((bookTrailer) => {
      const trailerNumberControl = this.registrationForm.get('trailerNumber');
      if (bookTrailer) {
        trailerNumberControl.setValidators([Validators.required, Validators.maxLength(15)]);
      } else {
        trailerNumberControl.setValidators([]);
      }
      trailerNumberControl.updateValueAndValidity();
    });
  }

  get vehiclesFormArray(): FormArray {
    return this.jobDetails.get('vehicles') as FormArray;
  }

  createVehicleGroup(): FormGroup {
    const newGroup = this.fb.group({
      // Fields specific to each vehicle
      vehicleRegistration: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      vehicleMileage: new FormControl(null, [Validators.required, Validators.maxLength(7)]),
      vehicleLocationDetails: new FormControl(null, [Validators.required, Validators.maxLength(200)]),
      geoLocationGuid: new FormControl(null),
      vehicleAvailability: new FormControl([], [Validators.required]),
      vehicleLocationCategory: new FormControl('3'),
      tyreType: new FormControl(null, Validators.required),
      spareWheel: new FormControl(null, Validators.required),
      applicationType: new FormControl(null, Validators.required),
      customerDefectNumber: new FormControl(null, [Validators.maxLength(30)]),
      customerOrderNumber: new FormControl(null, [Validators.maxLength(30)]),
      driverContactNo: new FormControl(null, [Validators.required, Validators.pattern(/^\d{11}$/), Validators.maxLength(15)]),
      driverEmail: new FormControl(null, [Validators.email, Validators.maxLength(100)]),
      driverFirstName: new FormControl(null, [Validators.maxLength(50)]),
      driverLastName: new FormControl(null, [Validators.maxLength(50)]),
      driverTitle: new FormControl('', [Validators.maxLength(10)]),
      userIsDriver: new FormControl(null, [Validators.required]),
      activeVehicle: new FormControl(null),
      requestSummaryString: new FormControl(''),
      fleetListId: new FormControl(null),
      jobItems: new FormControl([]),
      jobType: new FormControl(null, [Validators.required]), // New control for selecting between Tyres and Services
      comments: new FormControl('', [Validators.maxLength(150)])
    });

    // Sets whether the driver fields are required
    newGroup.controls.userIsDriver.valueChanges.subscribe((value) => {
      if (value) {
        // Clear validators for all driver fields when the user is the driver
        newGroup.controls.driverFirstName.clearValidators();
        newGroup.controls.driverLastName.clearValidators();
        newGroup.controls.driverContactNo.clearValidators();
      } else {
        // Set validators for all driver fields when the user is not the driver
        newGroup.controls.driverFirstName.setValidators([Validators.required]);
        newGroup.controls.driverLastName.setValidators([Validators.required]);
        newGroup.controls.driverContactNo.setValidators([Validators.required, Validators.pattern(/^\d{11}$/)]);
      }

      // Update validity for all driver fields
      newGroup.controls.driverFirstName.updateValueAndValidity();
      newGroup.controls.driverLastName.updateValueAndValidity();
      newGroup.controls.driverContactNo.updateValueAndValidity();
    });

    // Subscribe to changes in 'userIsDriver' and 'jobItems' to update error flags
    const index = this.vehiclesFormArray.length; // Get the index of the new group
    newGroup.get('userIsDriver')?.valueChanges.subscribe(() => {
      this.updateUserIsDriverError(index);
    });

    newGroup.get('jobItems')?.valueChanges.subscribe((items) => {
      this.updateJobItemsError(index, items);
    });

    // Subscribe to changes in 'jobType' to update validation for moved controls
    newGroup.get('jobType')?.valueChanges.subscribe((value) => {
      this.setTyreServiceValidators(newGroup, value === 'Tyres');
    });

    return newGroup;
  }

  setTyreServiceValidators(vehicleGroup: FormGroup, isTyreService: boolean) {
    if (isTyreService) {
      vehicleGroup.controls['tyreType'].setValidators([Validators.required]);
      vehicleGroup.controls['spareWheel'].setValidators([Validators.required]);
      vehicleGroup.controls['applicationType'].setValidators([Validators.required]);
    } else {
      vehicleGroup.controls['tyreType'].clearValidators();
      vehicleGroup.controls['spareWheel'].clearValidators();
      vehicleGroup.controls['applicationType'].clearValidators();
    }
    vehicleGroup.controls['tyreType'].updateValueAndValidity();
    vehicleGroup.controls['spareWheel'].updateValueAndValidity();
    vehicleGroup.controls['applicationType'].updateValueAndValidity();
  }

  updateUserIsDriverError(index: number) {
    const vehicleGroup = this.vehiclesFormArray.at(index) as FormGroup;
    this.showUserIsDriverError[index] = vehicleGroup.get('userIsDriver').invalid;
  }

  updateJobItemsError(index: number, items: any[]) {
    this.showJobItemsError[index] = !items || items.length === 0;
  }

  addVehicle(): void {
    const vehicles = this.jobDetails.get('vehicles') as FormArray;
    vehicles.push(this.createVehicleGroup());
  }

  confirmRegistration() {
    const registrationForm = this.registrationForm.value;
    if (this.registrationForm.controls['registration'].value.length >= 1) {
      this.gettingVehicle = true;
      this.bookingService.getVehiclesByRegistration(registrationForm).subscribe({
        next: (result) => {
          this.gettingVehicle = false;
          this.dialog
            .open(VehicleConfirmationDialogComponent, { data: { vehicle: result } })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                // If it's a truck, we don't need the Tyre Type field.
                if (this.checkIfTruck(result)) {
                  this.setTruckState();
                }
                this.setActiveVehicle(result, 0);
                this.vehicleConfirmed = true;
              } else {
                this.registrationForm.reset();
              }
            });
        },
        error: (error) => {
          this.gettingVehicle = false;
          // Check if the error is an exception message and set a flag
          const isExceptionMessage =
            error.error instanceof Error || (typeof error.error === 'string' && error.error.includes('System.Data.SqlClient.SqlException'));

          let errorAction = 'Please contact your Fleet Manager.';
          if (error.error && typeof error.error === 'string' && error.error.startsWith('We have been unable to automatically')) {
            errorAction = 'Please call us on 01253 229102 so we can assist you further';
          }

          this.routeToError('We have been unable to proceed with your request', error.error, errorAction, isExceptionMessage);
        }
      });
    }
  }

  setActiveVehicle(vehicle: any, vehicleIndex: number) {
    const vehicleGroup = (this.jobDetails.get('vehicles') as FormArray).at(vehicleIndex) as FormGroup;
    vehicleGroup.patchValue({
      activeVehicle: vehicle,
      fleetListId: vehicle.customerFleetListId,
      vehicleRegistration: vehicle.customerFleetlistVehicleReg
    });
    this.duplicateResults[vehicleIndex] = [];
  }

  checkIfTruck(vehicle: DpVehicleViewModel) {
    return vehicle.vehicleLayout?.vehicleGroupShort === 'TRU';
  }

  setTruckState() {
    this.isTruck = true;
    // Set the tyreType control value to "Standard" for trucks
    const vehiclesArray = this.jobDetails.get('vehicles') as FormArray;
    const vehicleGroup = vehiclesArray.at(0) as FormGroup;
    vehicleGroup.patchValue({ tyreType: 'Standard' });
  }

  appendTyreDetails(index: number) {
    const vehicleGroup = (this.jobDetails.get('vehicles') as FormArray).at(index) as FormGroup;
    const jobItems = vehicleGroup.get('jobItems')?.value || [];
    const jobType = vehicleGroup.get('jobType')?.value;

    let summary = jobItems
      .map((item: any) => {
        const { positionCode, tyreSize, removalReason, nonTyreService, speedRating, loadRating, axleType } = item;
        const reason = removalReason ? removalReason.name : nonTyreService ? nonTyreService.name : 'No reason/service provided';
        return `${positionCode} (${axleType}) (${tyreSize} ${speedRating}${loadRating}): ${reason}`;
      })
      .join('\n');

    const applicationType = vehicleGroup.get('applicationType')?.value;
    const hasSpareWheel = vehicleGroup.get('spareWheel')?.value;
    const tyreType = vehicleGroup.get('tyreType')?.value;

    if (applicationType) {
      summary += `\nApplication Type: ${applicationType}`;
    }
    if (hasSpareWheel !== null) {
      summary += `\nHas Spare Wheel: ${hasSpareWheel ? 'Yes' : 'No'}`;
    }
    if (tyreType) {
      summary += `\nTyre Type: ${tyreType}`;
    }

    const comments = vehicleGroup.get('comments')?.value;
    if (comments) {
      summary += `\nComments: ${comments}`;
    }

    vehicleGroup.patchValue({ requestSummaryString: summary });
  }

  confirmSubmission() {
    this.isSubmitting = true;

    // Generate request summaries for each vehicle
    const vehiclesArray = this.jobDetails.get('vehicles') as FormArray;
    for (let i = 0; i < vehiclesArray.length; i++) {
      if (!this.detailsAppended) {
        this.appendTyreDetails(i);
      }
    }

    // If spareWheel control is null set it to false
    vehiclesArray.controls.forEach((vehicleGroup: any) => {
      if (vehicleGroup.get('spareWheel')?.value === null) {
        vehicleGroup.patchValue({ spareWheel: false });
      }
    });

    // If we get an error back from the API, we don't want things appending twice to the summaries when we resubmit
    this.detailsAppended = true;

    const formData = this.jobDetails.value;

    // Remove the activeVehicle property from the request body
    formData.vehicles.forEach((vehicle: any) => {
      delete vehicle.activeVehicle;
    });

    // Save the users detail to cookies
    this.saveToCookies();

    // Submit the job
    this.bookingService.addNewJob(formData).subscribe({
      next: (result: any) => {
        // Handle the successful response
        this.isSubmitting = false;
        this.router.navigate(['/ext/booking/success'], { state: { spRef: result[0] } });
      },
      error: (error) => {
        const isExceptionMessage =
          error.error instanceof Error || (typeof error.error === 'string' && error.error.includes('System.Data.SqlClient.SqlException'));
        this.routeToError(
          'We have been unable to proceed with your request',
          error.error,
          'Please call us on 01253 229102 so we can assist you further.',
          isExceptionMessage
        );
        this.isSubmitting = false;
      }
    });
  }

  submitJob() {
    this.formSubmitted = true;
    let isAnyVehicleInvalid = false;

    // Trigger validation for each vehicle group
    this.vehiclesFormArray.controls.forEach((vehicleGroup, index) => {
      this.triggerValidation(vehicleGroup as FormGroup);
      this.showUserIsDriverError[index] = vehicleGroup.get('userIsDriver').invalid;
      this.showJobItemsError[index] = !vehicleGroup.get('jobItems')?.value || vehicleGroup.get('jobItems')?.value.length === 0;
      if (vehicleGroup.invalid || this.showUserIsDriverError[index] || this.showJobItemsError[index]) {
        isAnyVehicleInvalid = true;
      }
    });

    if (isAnyVehicleInvalid || this.jobDetails.invalid) {
      return;
    }

    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          confirmationTitle: 'Ready to Submit?',
          confirmationMessage: 'Are you sure you want to submit this job?'
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.confirmSubmission();
        }
      });
  }

  setVehicleLocation($event: any, vehicleIndex: number) {
    const vehiclesArray = this.jobDetails.get('vehicles') as FormArray;
    const vehicleGroup = vehiclesArray.at(vehicleIndex) as FormGroup;

    // if the user has cleared the input, then set the values to null
    if ($event === null) {
      vehicleGroup.patchValue({
        vehicleLocationDetails: null,
        geoLocationGuid: null
      });
    } else {
      vehicleGroup.patchValue({
        geoLocationGuid: $event.geolocationGUID,
        vehicleLocationDetails: $event.fullAddress
      });
    }
  }

  triggerValidation(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        this.triggerValidation(control);
      }
    });
  }

  nextStep(stepper: MatStepper, step: string, vehicleIndex: number) {
    const vehicleGroup = this.vehiclesFormArray.at(vehicleIndex) as FormGroup;
    this.triggerValidation(this.jobDetails);

    // Additional check for 'Vehicle Details' step when userIsDriver is false
    if (step === 'Vehicle Details' && vehicleGroup.get('userIsDriver')?.value === false) {
      const driverFieldsValid = this.driverFieldsComplete(vehicleGroup);
      if (!driverFieldsValid) {
        this.triggerValidation(this.jobDetails);
        this.triggerValidationForDriversDetails(vehicleIndex);
        // If driver fields are not complete, prevent proceeding to the next step
        return;
      }
    }

    // Handle 'Vehicle Details' step
    if (step === 'Vehicle Details' && !vehicleGroup.get('activeVehicle')?.value && this.vehicleDetailsComplete(vehicleIndex)) {
      // Route the user to an error page
      this.router.navigate(['/ext/booking/error']);
      return;
    }

    // Handle 'Vehicle Availability' step
    if (step === 'Vehicle Availability' && !this.vehicleAvailabilityComplete(vehicleIndex)) {
      // Trigger validation for the current step
      this.triggerValidationForVehicleAvailability(vehicleIndex);
      return;
    }

    // Handle 'Tyre & Defect Details' step
    if (step === 'Tyre & Defect Details' && !this.tyreDetailsComplete(vehicleIndex)) {
      this.triggerValidationForTyreDetails(vehicleIndex);
      return;
    }

    this.attemptedNextStep = true;
    stepper.next();
  }

  driverFieldsComplete(vehicleGroup: FormGroup): boolean {
    const driverFields = ['driverFirstName', 'driverLastName', 'driverContactNo'];
    return driverFields.every((field) => vehicleGroup.get(field)?.valid);
  }

  vehicleDetailsComplete(vehicleIndex: number): boolean {
    // Access the specific vehicle group form
    const vehicleGroup = this.vehiclesFormArray.at(vehicleIndex) as FormGroup;

    // List of form control names in the 'Vehicle Details' section
    const vehicleDetailsControls = [
      'vehicleRegistration',
      'vehicleMileage',
      'vehicleLocationDetails',
      'userIsDriver',
      'vehicleLocationDetails'
    ];
    // Check if all the relevant controls are valid
    return vehicleDetailsControls.every((controlName) => vehicleGroup.get(controlName)?.valid);
  }

  triggerValidationForVehicleAvailability(vehicleIndex: number) {
    // Access the specific vehicle group form
    const vehicleGroup = this.vehiclesFormArray.at(vehicleIndex) as FormGroup;
    vehicleGroup.get('vehicleAvailability')?.markAsTouched();
    vehicleGroup.get('vehicleAvailability')?.updateValueAndValidity();
  }

  triggerValidationForDriversDetails(vehicleIndex: number) {
    // Access the specific vehicle group form
    const vehicleGroup = this.vehiclesFormArray.at(vehicleIndex) as FormGroup;
    const driverFields = ['driverFirstName', 'driverLastName', 'driverContactNo'];
    driverFields.forEach((field) => {
      vehicleGroup.get(field)?.markAsTouched();
      vehicleGroup.get(field)?.updateValueAndValidity();
    });
  }

  vehicleAvailabilityComplete(vehicleIndex: number) {
    // Access the specific vehicle group form
    const vehicleGroup = this.vehiclesFormArray.at(vehicleIndex) as FormGroup;

    // Check if all the relevant controls are valid
    return vehicleGroup.get('vehicleAvailability')?.valid;
  }

  tyreDetailsComplete(vehicleIndex: number): boolean {
    const vehicleGroup = this.vehiclesFormArray.at(vehicleIndex) as FormGroup;
    const jobItems = vehicleGroup.get('jobItems')?.value;
    const jobType = vehicleGroup.get('jobType')?.value;

    if (jobType === 'Tyres') {
      return (
        jobItems &&
        jobItems.length > 0 &&
        vehicleGroup.get('tyreType')?.valid &&
        vehicleGroup.get('spareWheel')?.valid &&
        vehicleGroup.get('applicationType')?.valid
      );
    } else {
      return jobItems && jobItems.length > 0;
    }
  }

  triggerValidationForTyreDetails(vehicleIndex: number) {
    const vehicleGroup = this.vehiclesFormArray.at(vehicleIndex) as FormGroup;
    const jobItems = vehicleGroup.get('jobItems');
    if (!jobItems || jobItems.value.length === 0) {
      vehicleGroup.setErrors({ noJobItems: true });
      this.showJobItemsError[vehicleIndex] = true;
    } else {
      this.showJobItemsError[vehicleIndex] = false;
    }

    if (vehicleGroup.get('jobType')?.value === 'Tyres') {
      vehicleGroup.get('tyreType')?.markAsTouched();
      vehicleGroup.get('spareWheel')?.markAsTouched();
      vehicleGroup.get('applicationType')?.markAsTouched();

      vehicleGroup.get('tyreType')?.updateValueAndValidity();
      vehicleGroup.get('spareWheel')?.updateValueAndValidity();
      vehicleGroup.get('applicationType')?.updateValueAndValidity();
    }
  }

  routeToError(errorTitle: string, errorMessage: string, errorAction: string, isExceptionMessage: boolean) {
    // If it's an exception, we don't want to show that to our customers
    if (isExceptionMessage) {
      errorMessage = 'An unexpected error occurred';
      errorAction = 'Please try again';
    }

    this.router.navigate(['/ext/booking/error'], {
      state: {
        errorTitle: errorTitle,
        errorMessage: errorMessage,
        errorAction: errorAction
      }
    });
  }

  saveToCookies() {
    const { userContactNumber, userEmail, userFirstName, userLastName, userTitle } = this.jobDetails.value;

    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1); // Set expiration date to 1 year from now

    this.cookieService.set('userContactNumber', userContactNumber, expires);
    this.cookieService.set('userEmail', userEmail, expires);
    this.cookieService.set('userFirstName', userFirstName, expires);
    this.cookieService.set('userLastName', userLastName, expires);
    this.cookieService.set('userTitle', userTitle, expires);
  }

  setTrailerStatus(value: boolean | null) {
    this.bookTrailer.next(value);
  }

  public handleAvailabilitySelection(selectedTimeSlots: selectedDateTimeSlot[]): void {
    const vehicleGroup = this.vehiclesFormArray.at(0) as FormGroup;

    vehicleGroup.patchValue({
      vehicleAvailability: selectedTimeSlots
    });

    this.triggerValidationForVehicleAvailability(0);
  }

  public resetBooking() {
    // Reset form groups to their initial state
    this.jobDetails.reset();
    this.registrationForm.reset();

    // Clear FormArray vehicles
    (this.jobDetails.get('vehicles') as FormArray).clear();

    // Reinitialize vehicles FormArray
    this.addVehicle();

    // Reset component state variables
    this.activeVehicle = null;
    this.vehicleConfirmed = false;
    this.duplicateResults = [];
    this.isTruck = false;
    this.formSubmitted = false;
    this.gettingVehicle = false;
    this.showUserIsDriverError = [];
    this.showJobItemsError = [];
    this.submissionError = null;
    this.isSubmitting = false;
    this.attemptedNextStep = false;
    this.detailsAppended = false;

    // Reset the BehaviorSubject for bookTrailer
    this.bookTrailer.next(null);
  }
}
